exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-client-account-tsx": () => import("./../../../src/pages/activateClientAccount.tsx" /* webpackChunkName: "component---src-pages-activate-client-account-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-cerere-racordare-[step]-tsx": () => import("./../../../src/pages/cerereRacordare/[step].tsx" /* webpackChunkName: "component---src-pages-cerere-racordare-[step]-tsx" */),
  "component---src-pages-cerere-racordare-index-tsx": () => import("./../../../src/pages/cerereRacordare/index.tsx" /* webpackChunkName: "component---src-pages-cerere-racordare-index-tsx" */),
  "component---src-pages-cereri-acces-consumuri-js": () => import("./../../../src/pages/cereri/accesConsumuri.js" /* webpackChunkName: "component---src-pages-cereri-acces-consumuri-js" */),
  "component---src-pages-cereri-audienta-index-tsx": () => import("./../../../src/pages/cereri/audienta/index.tsx" /* webpackChunkName: "component---src-pages-cereri-audienta-index-tsx" */),
  "component---src-pages-cereri-aviz-amplasament-[step]-tsx": () => import("./../../../src/pages/cereri/avizAmplasament/[step].tsx" /* webpackChunkName: "component---src-pages-cereri-aviz-amplasament-[step]-tsx" */),
  "component---src-pages-cereri-aviz-amplasament-index-tsx": () => import("./../../../src/pages/cereri/avizAmplasament/index.tsx" /* webpackChunkName: "component---src-pages-cereri-aviz-amplasament-index-tsx" */),
  "component---src-pages-cereri-cereri-pdf-tsx": () => import("./../../../src/pages/cereri/cereriPDF.tsx" /* webpackChunkName: "component---src-pages-cereri-cereri-pdf-tsx" */),
  "component---src-pages-cereri-duplicat-contract-[step]-tsx": () => import("./../../../src/pages/cereri/duplicatContract/[step].tsx" /* webpackChunkName: "component---src-pages-cereri-duplicat-contract-[step]-tsx" */),
  "component---src-pages-cereri-duplicat-contract-index-tsx": () => import("./../../../src/pages/cereri/duplicatContract/index.tsx" /* webpackChunkName: "component---src-pages-cereri-duplicat-contract-index-tsx" */),
  "component---src-pages-cereri-furnizare-[step]-tsx": () => import("./../../../src/pages/cereri/furnizare/[step].tsx" /* webpackChunkName: "component---src-pages-cereri-furnizare-[step]-tsx" */),
  "component---src-pages-cereri-furnizare-index-tsx": () => import("./../../../src/pages/cereri/furnizare/index.tsx" /* webpackChunkName: "component---src-pages-cereri-furnizare-index-tsx" */),
  "component---src-pages-cereri-schimbare-proprietar-[step]-tsx": () => import("./../../../src/pages/cereri/schimbareProprietar/[step].tsx" /* webpackChunkName: "component---src-pages-cereri-schimbare-proprietar-[step]-tsx" */),
  "component---src-pages-cereri-schimbare-proprietar-index-tsx": () => import("./../../../src/pages/cereri/schimbareProprietar/index.tsx" /* webpackChunkName: "component---src-pages-cereri-schimbare-proprietar-index-tsx" */),
  "component---src-pages-contact-chirnogi-tsx": () => import("./../../../src/pages/contact/Chirnogi.tsx" /* webpackChunkName: "component---src-pages-contact-chirnogi-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-miroslava-tsx": () => import("./../../../src/pages/contact/Miroslava.tsx" /* webpackChunkName: "component---src-pages-contact-miroslava-tsx" */),
  "component---src-pages-contact-odobesti-tsx": () => import("./../../../src/pages/contact/Odobesti.tsx" /* webpackChunkName: "component---src-pages-contact-odobesti-tsx" */),
  "component---src-pages-contact-panciu-tsx": () => import("./../../../src/pages/contact/Panciu.tsx" /* webpackChunkName: "component---src-pages-contact-panciu-tsx" */),
  "component---src-pages-contul-meu-index-tsx": () => import("./../../../src/pages/contulMeu/index.tsx" /* webpackChunkName: "component---src-pages-contul-meu-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-utile-centre-de-plata-index-tsx": () => import("./../../../src/pages/infoUtile/centreDePlata/index.tsx" /* webpackChunkName: "component---src-pages-info-utile-centre-de-plata-index-tsx" */),
  "component---src-pages-info-utile-conditii-standard-tsx": () => import("./../../../src/pages/infoUtile/conditiiStandard.tsx" /* webpackChunkName: "component---src-pages-info-utile-conditii-standard-tsx" */),
  "component---src-pages-info-utile-faqs-generale-tsx": () => import("./../../../src/pages/infoUtile/faqs/generale.tsx" /* webpackChunkName: "component---src-pages-info-utile-faqs-generale-tsx" */),
  "component---src-pages-info-utile-legislatie-acte-normative-tsx": () => import("./../../../src/pages/infoUtile/legislatie/acteNormative.tsx" /* webpackChunkName: "component---src-pages-info-utile-legislatie-acte-normative-tsx" */),
  "component---src-pages-info-utile-legislatie-proceduri-tsx": () => import("./../../../src/pages/infoUtile/legislatie/proceduri.tsx" /* webpackChunkName: "component---src-pages-info-utile-legislatie-proceduri-tsx" */),
  "component---src-pages-info-utile-legislatie-rapoarte-tsx": () => import("./../../../src/pages/infoUtile/legislatie/rapoarte.tsx" /* webpackChunkName: "component---src-pages-info-utile-legislatie-rapoarte-tsx" */),
  "component---src-pages-info-utile-oferte-tip-tsx": () => import("./../../../src/pages/infoUtile/oferteTip.tsx" /* webpackChunkName: "component---src-pages-info-utile-oferte-tip-tsx" */),
  "component---src-pages-info-utile-pcs-tsx": () => import("./../../../src/pages/infoUtile/pcs.tsx" /* webpackChunkName: "component---src-pages-info-utile-pcs-tsx" */),
  "component---src-pages-info-utile-registru-evidenta-tsx": () => import("./../../../src/pages/infoUtile/registruEvidenta.tsx" /* webpackChunkName: "component---src-pages-info-utile-registru-evidenta-tsx" */),
  "component---src-pages-info-utile-tarife-tsx": () => import("./../../../src/pages/infoUtile/tarife.tsx" /* webpackChunkName: "component---src-pages-info-utile-tarife-tsx" */),
  "component---src-pages-inregistrare-tsx": () => import("./../../../src/pages/inregistrare.tsx" /* webpackChunkName: "component---src-pages-inregistrare-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-plangeri-si-sesizari-tsx": () => import("./../../../src/pages/plangeriSiSesizari.tsx" /* webpackChunkName: "component---src-pages-plangeri-si-sesizari-tsx" */),
  "component---src-pages-utils-undo-email-swap-tsx": () => import("./../../../src/pages/utils/undoEmailSwap.tsx" /* webpackChunkName: "component---src-pages-utils-undo-email-swap-tsx" */)
}

