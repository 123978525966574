import * as Sentry from "@sentry/gatsby"

console.log("Sentry conf NODE_ENV: ", process.env.NODE_ENV)
// if not in development, init sentry
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://578d80c87224a72999d0b0640223f5fb@o236732.ingest.us.sentry.io/4507095955275776",
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    //   replaysSessionSampleRate: 0.1,
    //   replaysOnErrorSampleRate: 1.0,
  })
}
